body {
    background-color: #222;
    color: #fff;
}

body#top .ui {
    font-family: 'orkneylight', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.ui.segment {
    background-color: #333;
    font-size: 1.2rem;
}

#page_header {
    align-self: flex-start;
    margin-right: auto;

    & > a {
        color: #fff;
        align-items: flex-start;
    }
}

#notMeNAu {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    justify-content: center;

    a.item {
        color: #fff;

        &:hover {
            background: rgba(127,127,127,.2);
        }
    }
}

body .ui.list > .item .header, body .ui.list > .item .description {
    color: white;
}

p {
    text-align: left;
}

.ui.header {
    color: inherit;
}

h1.ui.center.header {
    margin-top: 1em;
}

h2.ui.center.header {
    margin: 4em 0em 2em;
}

h3.ui.center.header {
    margin-top: 2em;
    padding: 2em 0em;
}

p.back_to_top {
    margin: 0;
    padding: 0;
}

p.back_to_top+h2.ui.header {
    margin-top: 0;
    padding-top: 0;
}

pre {
    white-space: pre-wrap;
    padding: 0.5em;
}

iframe {
    max-width: 100%;
}

.cv-buttons .button {
  margin-top: 1em;
}

.cv {
  h4 {
    margin-top: 1em;
  }
}

.skills {
  table, table.ui.table {
    td.td-2 {
      font-style: italic;
      font-weight: normal;
    }

    td > span {
      &:after {
        content: ","; // Middot: \00B7
        margin-left: 0.3em;
        margin-right: 0.3em;
      }

      &:nth-child(even) {
        font-style: italic;
      }

      &:last-child {
        &:after {
          content: "";
        }
      }

    }

  }
}

#page_head {
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

#header-contact-details {
    display: none;
}

@media only screen and (max-width: 1000px) {
    #notMeNAu {
        .item {
            min-width: 0;
            padding: 0.5rem;

            .icon {
                margin: 0 !important;
            }

            .text {
                display: none;
            }
        }
    }
}

@media print {
    #notMeNAu {
        display: none;
    }

    #page_head {
        align-items: flex-start;
    }

    #page_header span {
        display: none;
    }

    #header-contact-details {
        display: block;

        margin-left: auto;

        & > .row {
            padding: 0.1rem;
        }
    }

    .cv-buttons, .ui.container.cv-subheading-links, .cv-mobile-secondary {
        display: none;
    }

    .ui.raised.segment {
        box-shadow: none;
        border: none;
        padding: 0;
        border-radius: 0;

        page-break-after: always;
    }
}

li {
    margin-top: 2em;
}
