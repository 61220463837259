body {
    background: #210025;
    color: #fff;
}

body#top .ui {
    font-family: 'orkneylight', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.ui.segment {
    background-color: #a5b9ce34;
    font-size: 1.2rem;
}

#page_head {
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

#page_header {
    align-self: center;
    margin-left: auto;
    margin-right: auto;

    & > a {
        color: #fff;
        align-items: flex-start;
    }
}

#notMeNAu {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    & > h2 {
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0.75rem;
        font-size: 1.2rem;
    }

    & > div.ui.grid {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }

    a.item {
        color: #fff;

        &:hover {
            background: rgba(127,127,127,.2);
        }
    }
}

#header-contact-details {
    display: none;
}

body .ui.list > .item .header, body .ui.list > .item .description {
    color: white;
}

.ui a {
    color: #ff79a6;
}

p {
    text-align: left;
}

.ui.header {
    color: inherit;
}

h1.ui.center.header {
    margin-top: 1em;
}

h2.ui.center.header {
    margin: 4em 0em 2em;
}

h3.ui.center.header {
    margin-top: 2em;
    padding: 2em 0em;
}

p.back_to_top {
    margin: 0;
    padding: 0;
}

p.back_to_top+h2.ui.header {
    margin-top: 0;
    padding-top: 0;
}

pre {
    white-space: pre-wrap;
    padding: 0.5em;
}

iframe {
    max-width: 100%;
}

.cv-buttons .button {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 1em;
    margin-bottom: 1em;
}

.cv {
  h3 {
    margin-top: 2em;

    span:first-child {
        font-size: 85%;
        font-style: italic;
        display: inline-block;
        margin-bottom: 5px;
    }
    span:last-child {
        font-size: 105%;
        display: inline-block;
    }
  }

  .time {
    font-size: 85%;
    margin: 0;
    margin-bottom: 1em;
  }
}

.skills {
  table, table.ui.table {
    td.td-2 {
      font-style: italic;
      font-weight: normal;
    }

    td > span {
      &:after {
        content: ","; // Middot: \00B7
        margin-left: 0.3em;
        margin-right: 0.3em;
      }

      &:nth-child(even) {
        font-style: italic;
      }

      &:last-child {
        &:after {
          content: "";
        }
      }

    }

  }
}

footer {
}

@media only screen and (max-width: 1000px) {
    #notMeNAu {
        .item {
            min-width: 0;
        }
    }
}

@media print {
    #notMeNAu {
        display: none;
    }

    #page_head {
        align-items: flex-start;
    }

    #page_header span {
        display: none;
    }

    #header-contact-details {
        display: block;

        margin-left: auto;

        & > .row {
            padding: 0.1rem;
        }
    }

    .cv-buttons, .ui.container.cv-subheading-links, .cv-mobile-secondary {
        display: none;
    }

    .ui.raised.segment {
        box-shadow: none;
        border: none;
        padding: 0;
        border-radius: 0;

        page-break-after: always;
    }

    .print-hidden {
        display: none !important;
    }

    footer {
        display: none;
    }
}

li:before {
    color: inherit !important;
}
